import React from 'react';

import {
  Layout, Separator, Typography, Image,
} from '../components';

import { Figure, FigureDouble, FigureTriple } from '../components/opComponents';

const SosMain = () => (
  <Layout
    title="SOS Main à Paris : Contactez le Docteur Ghazanfari !"
    description="Le Docteur Ghazanfari assure la prise en charge des urgences médicales pour vos opérations des membres supérieurs (plaies, infections, fractures...)"
    headerStyle="white"
  >
    <div className="mx-auto bg-white max-w-screen-xlg">
      <div className="flex flex-col px-4 py-12 space-y-4 text-center bg-white rounded">
        <Typography className="uppercase tracking-[0.3em]" variant="h1" color="secondary-deep" size="lg" weight="bold">
          SOS main
          {' '}
          <Typography className="break-all uppercase tracking-[0.3em]" variant="span" color="secondary-deep" size="lg" weight="bold">d&apos;Aubergenville</Typography>
        </Typography>
      </div>
      <Image className="mx-auto max-w-[800px]" imageName="intro-sos.jpg" />
      <div className="flex flex-col px-4 py-16 mx-auto space-y-12 max-w-screen-xlg">
        <div className="flex flex-col space-y-4 text-justify">
          <Typography>
            Il s&apos;agit du
            {' '}
            <Typography variant="span" weight="bold">premier centre SOS-MAIN du département des Yvelines (78)</Typography>
            , accrédité
            par la FESUM (Fédération Européenne des Services d&apos;Urgences de la Main).
          </Typography>
          <Typography>
            L&apos;unité UMVS (Urgence Main Val de Seine), créée il y a plus de 20 ans, prend en
            charge toutes les pathologies urgentes et programmées du membre supérieur.
          </Typography>
          <Typography>
            Le Docteur Ghazanfari est membre, depuis de nombreuses années, de ce pôle
            d’excellence.
          </Typography>
        </div>
        <div className="flex flex-col space-y-4 text-justify">
          <Typography className="uppercase" variant="h2" color="primary" size="mdx" weight="semiBold">Accessibilité et parcours de soins</Typography>
          <Separator />
          <Typography>
            Notre unité, en collaboration étroite et permanente avec les
            médecins de ville et les structures d&apos;urgences extra-
            hospitalières (Pompiers et SAMU), permet la prise en charge
            24h/24 et 7J/7 de plus de
            {' '}
            <Typography variant="span" weight="bold">3000 urgences du membre supérieur</Typography>
            {' '}
            au bloc opératoire chaque année. Le service des
            urgences est joignable au 0810.015.015.
          </Typography>
          <Typography>
            Notre structure dispose d&apos;un personnel médical et paramédical
            compétent et est équipée d&apos;un plateau technique de pointe
            (microscope, matériel de microchirurgie, plaques verrouillées,
            vis à compression à tête enfouie, fixateurs externes...)
          </Typography>
          <Typography className="py-8 text-center uppercase" variant="h2" color="red" size="mdx" weight="semiBold">
            Quelles sont les urgences de la main prises en charge dans notre centre?
          </Typography>
        </div>
      </div>
      <div className="flex flex-col pb-16 space-y-16">
        <div className="grid items-center grid-cols-1 gap-6 px-4 mdx:grid-cols-2">
          <div className="flex flex-col my-6 space-y-4 bg-white rounded">
            <Typography className="uppercase" variant="h2" color="primary" size="mdx" weight="semiBold">1. Plaies de la main</Typography>
            <Separator />
            <Typography className="text-justify">
              Toute
              {' '}
              <Typography variant="span" weight="bold">plaie de la main</Typography>
              {' '}
              , même si elle paraît banale et non compliquée, doit être explorée
              au bloc opératoire par un chirurgien de la main. En effet, ces plaies
              s&apos;accompagnent très fréquemment d&apos;une section (complète ou partielle)
              d&apos;une structure noble (tendon, artère et nerf).
              L&apos;absence de prise en charge rapide et adaptée d&apos;
              <Typography variant="span" weight="bold">une plaie de la main</Typography>
              {' '}
              est une perte de chance pour le patient
              et est le plus souvent responsable de nombreuses complications et de séquelles
              :
              {' '}
              <Typography variant="span" weight="bold">infection, limitation de la mobilité du doigt, névrome</Typography>
              ...
            </Typography>
          </div>
          <div className="flex flex-row items-start">
            <Figure
              imageName="sos-main-1a.jpg"
              caption="Plaie du poignet avec section de l’artère ulnaire suturée au bloc opératoire"
            />
            <Figure
              imageName="sos-main-1b.jpg"
              caption="Plaie transfixiante de la main par corps étranger nécessitant une extraction au bloc opératoire"
            />
            <Figure
              imageName="sos-main-1c.jpg"
              caption="Plaie délabrante du pouce avec fracture ouverte ayant été suturée au bloc opératoire"
            />
          </div>
        </div>
        <div className="grid items-center grid-cols-1 gap-6 px-4 mdx:grid-cols-2">
          <div className="flex flex-col my-6 space-y-4 bg-white rounded">
            <Typography className="uppercase" variant="h2" color="primary" size="mdx" weight="semiBold">2. Infections de la main</Typography>
            <Separator />
            <Typography className="text-justify">
              Toutes
              {' '}
              <Typography variant="span" weight="bold">les morsures, les arthrites</Typography>
              {' '}
              (infections de l&apos;articulation),
              {' '}
              <Typography variant="span" weight="bold">les abcès et les phlegmons des gaines des fléchisseurs de la main</Typography>
              {' '}
              doivent être prise en charge en urgence au bloc opératoire.
              Ces infections engagent le pronostic
              fonctionnel de la main et peuvent se généraliser (septicémie)
              provoquant ainsi des dommages
              irréversibles.
            </Typography>
          </div>
          <div className="flex flex-row items-start">
            <Figure
              imageName="sos-main-2a.jpg"
              caption="Panarais nécessitant une prise en charge au bloc opératoire"
            />
            <FigureDouble
              imageNameOne="sos-main-2b.jpg"
              imageNameTwo="sos-main-2c.jpg"
              caption="Phlegmon digital avec lavage de la gaine des fléchisseurs au sérum physiologique"
            />
          </div>
        </div>
        <div className="grid items-center grid-cols-1 gap-6 px-4 mdx:grid-cols-2">
          <div className="flex flex-col my-6 space-y-4 bg-white rounded">
            <Typography className="uppercase" variant="h2" color="primary" size="mdx" weight="semiBold">3. Fractures de la main</Typography>
            <Separator />
            <Typography className="text-justify">
              De nos jours, l&apos;ensemble
              {' '}
              <Typography variant="span" weight="bold">des fractures du membre supérieure</Typography>
              {' '}
              (clavicule, épaule, coude, avant-bras, poignet et main) peut
              être pris en charge de manière
              optimale. Nous disposons du matériel de pointe afin de prendre
              en charge ces différentes
              fractures : plaques verrouillées, vis à compression...
            </Typography>
          </div>
          <FigureTriple
            imageNameOne="sos-main-3a.jpg"
            imageNameTwo="sos-main-3b.jpg"
            imageNameThree="sos-main-3c.jpg"
            caption="Fracture déplacée et instable de la première phalange de l’auriculaire traitée par un double vissage en compression"
            currentOpTitle="Fracture de la main"
          />
        </div>

        <div className="flex flex-col px-4 my-6 space-y-4 bg-white rounded">
          <Typography className="uppercase" variant="h2" color="primary" size="mdx" weight="semiBold">4. Injection sous pression</Typography>
          <Separator />
          <Typography className="text-justify">
            C&apos;est l&apos;injection accidentelle sous pression
            par un pistolet, d&apos;un produit toxique
            (peinture, graisse, huile...) au niveau de la pulpe du doigt. Le produit injecté
            est responsable d&apos;une double toxicité (irritation
            chimique et distension physique),
            {' '}
            <Typography variant="span" weight="bold">entrainant rapidement une nécrose du doigt en cas de prise en charge retardée.</Typography>
          </Typography>
        </div>

        <div className="grid items-center grid-cols-1 gap-6 px-4 mdx:grid-cols-2">
          <div className="flex flex-col my-6 space-y-4 bg-white rounded">
            <Typography className="uppercase" variant="h2" color="primary" size="mdx" weight="semiBold">5. Réimplantation</Typography>
            <Separator />
            <Typography className="text-justify">
              En cas d&apos;amputation, il faut avoir quelques reflexes :
            </Typography>
            <ul className="flex flex-col">
              <li className="relative pl-5 before:top-[20px] before:absolute before:rounded-[50%] before:left-0 before:w-[8px] py-2 before:h-[8px] before:bg-primary">
                <Typography>
                  prévenir les pompiers ou le SAMU du département
                </Typography>
              </li>
              <li className="relative pl-5 before:top-[20px] before:absolute before:rounded-[50%] before:left-0 before:w-[8px] py-2 before:h-[8px] before:bg-primary">
                <Typography>
                  le fragment amputé doit être mis dans un sac plastique étanche,
                  entouré d&apos;un textile. Ce sac est ensuite placé lui-même dans
                  un autre sac plastique contenant des glaçons
                </Typography>
              </li>
              <li className="relative pl-5 before:top-[20px] before:absolute before:rounded-[50%] before:left-0 before:w-[8px] py-2 before:h-[8px] before:bg-primary">
                <Typography>
                  ne pas mettre de garrot sur la main. Il faut surélever
                  la main et faire un pansement compressif
                </Typography>
              </li>
              <li className="relative pl-5 before:top-[20px] before:absolute before:rounded-[50%] before:left-0 before:w-[8px] py-2 before:h-[8px] before:bg-primary">
                <Typography>
                  rester à jeun (ne pas manger, ne pas boire et ne pas fumer)
                  et noter l&apos;heure du dernier repas
                </Typography>
              </li>
            </ul>
            <Typography className="text-justify">
              Le chirurgien, après analyse du fragment amputé et du doigt sous
              microscope, posera l&apos;indication d&apos;une éventuelle réimplantation.
              En cas de réimplantation, une hospitalisation d&apos;une semaine et
              l&apos;arrêt du tabac seront nécessaires.
            </Typography>
          </div>
          <Figure
            maxWidth="w-full"
            imageName="sos-main-4.jpg"
            caption="Fragment amputé acheminé dans de bonnes conditions au bloc opératoire"
            imgClassName="max-h-[200px]"
            currentOpTitle="Injection sous pression"
          />
        </div>

        <div className="grid items-center grid-cols-1 gap-6 px-4 mdx:grid-cols-2">
          <div className="flex flex-col my-6 space-y-4 bg-white rounded">
            <Typography className="uppercase" variant="h2" color="primary" size="mdx" weight="semiBold">6. Lambeaux</Typography>
            <Separator />
            <Typography className="text-justify">
              En cas de perte de substances importante du membre supérieur
              (délabrement suite à un traumatisme, une infection...) une
              couverture par un lambeau peut être nécessaire afin de couvrir
              les éléments nobles (os, tendon, artère et nerf) de la main.
            </Typography>
          </div>
          <FigureTriple
            imageNameOne="sos-main-6a.jpg"
            imageNameTwo="sos-main-6b.jpg"
            imageNameThree="sos-main-6c.jpg"
            caption="Exemple d'une nécrose étendu du dos de la main, couvert par un leambeau prélévé sur l'avant bras qui est tourné de 180° afin de couvrir la main"
            currentOpTitle="Lambeaux"
          />
        </div>
      </div>
    </div>
  </Layout>
);

export default SosMain;
